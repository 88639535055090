<template>
  <v-container
    id="regular-tables-view"
    fluid
    tag="section"
  >
    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <material-card
          icon="mdi-clipboard-text"
          icon-small
          title="SOS Token Update"
          color="accent"
        >

        </material-card>
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <material-card
          icon="mdi-clipboard-text"
          icon-small
          title="SKU Dictionary Update "
          color="accent"
        >
          <v-card>
            <v-card-text>
              <v-col cols="12">
                Please read instruction first
                <ul>
                  <li>SKU upload only supports .csv file</li>
                  <li>You need to replace the full name of Category in the SKU file with the ID of the Item Category in the Testing Station</li>
                  <li>The SKU dictionary update will not affect the previous item attributes</li>
                  <li>Items synchronized after the dictionary update will use the new dictionary</li>
                  <li>Only SOS Items can be generating related attributes through SKU document</li>
                </ul>
              </v-col>
              <v-col cols="12">
                <v-file-input
                  v-model="skuFile"
                  show-size
                  counter
                  label="Upload SKU"
                  type="file"
                  accept=".csv"
                ></v-file-input>
              </v-col>
            </v-card-text>
            <v-card-actions>
              <v-col
                cols="12"
              >
                <v-btn
                  block
                  color="primary"
                  @click="uploadSku"
                >
                  Confirm Upload
                </v-btn>
              </v-col>
            </v-card-actions>
          </v-card>
        </material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import MaterialCard from '@/components/MaterialCard'
  import SnackBar from '@/components/app/SnackBar'
  import SystemSettingApi from '@/api/admin/system-setting'
  export default {
    name: 'SystemSetting',
    components: { MaterialCard, SnackBar },
    data () {
      return {
        skuFile: null,
      }
    },
    methods: {
      uploadSku () {
        const file = new FormData()
        file.append('sku_file', this.skuFile, this.skuFile.name)
        SystemSettingApi.fileUpload(file).then(response => {
          console.log(response)
        }).catch(error => {
          console.log(error)
        })
      },
    },
  }
</script>

<style scoped>

</style>
