import { apiConfig } from '../../plugins/config'
import axios from 'axios'

export default {
  fileUpload (formData) {
    return axios({
      method: 'post',
      url: apiConfig.apiUri + 'admin/uploadSku',
      data: formData,
    })
  },
}
